<template>
  <section class="aboutUs" data-aos="fade-up">
    <div class="__content">
      <img class="__curve" src="../../assets/icons/curva.svg" alt="green-curve">
      <div class="__card_row" v-for="(card) in data.cards" :key="card.id">
        <div class="___about_card shadow">
          <img :src="require(`../../assets/${card.img}`)" alt="aboutUs-card-img">
          <div>
            <h3><b>{{ card.title }}</b></h3>
            <p>{{ card.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import store from '../../store';

export default {
  name: "QuienesSomos",
  data() {
    return {
      data: {
        cards: [
          {
            id: 1,
            img: 'img/people.webp',
            title: 'Un grupo de empresarios y ejecutivos',
            text: 'Todos con muchos años de experiencia, que vendieron sus empresas o terminaron un ciclo en ellas'
          },
          {
            id: 2,
            img: 'img/lupa.webp',
            title: 'Se encontraron a inicios del año 2015',
            text: 'Explorando el nuevo mundo de los emprendedores.'
          },
          {
            id: 3,
            img: 'img/acompanae.webp',
            title: 'Luego de acompañar una gran cantidad de empresas',
            text: 'En distintas etapas de desarrollo empresarial'
          },
          {
            id: 4,
            img: 'img/incorporar.webp',
            title: 'Se incorporaron al importante ecosistema de apoyo',
            text: 'con incubadoras, aceleradoras , inversionistas que opera globalmente, cada uno haciendo su parte.'
          },
          {
            id: 5,
            img: 'img/reloj.webp',
            title: 'Luego de acompañar una gran cantidad de empresas',
            text: 'En distintas etapas de desarrollo empresarial'
          },
          {
            id: 6,
            img: 'img/meta.webp',
            title: 'Descubrieron que, aun así, los emprendimientos y empresas',
            text: 'En general mantienen brechas importantes que les impiden crecer con éxito real.'
          },
          {
            id: 7,
            img: 'img/lpiz.webp',
            title: 'Descubrieron que, aun así, los emprendimientos y empresas',
            text: 'Con programas de acompañamiento estrecho.'
          },
          {
            id: 8,
            img: 'img/exito.webp',
            title: 'El éxito y reconocimiento ha sido relevante',
            text: 'Lo que los llevó a crear y desarrollar WETFOREST que los invitamos a conocer.  '
          },

        ]
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    "$store.state.sections": function() {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
       if (store.state.sections.aboutUs) {
      this.data = {...store.state.sections.aboutUs.components};}
    }
  }
}
</script>

<style scoped lang="scss">
@use 'src/assets/scss/colors';
@use 'src/assets/scss/typography';

.vertical-background {
  left: 30%;
  z-index: -1;
  height: 100%;
}

.aboutUs {
  background-image: url("../../assets/img/x-horizontal-wave.svg");
  background-size: contain;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  min-height: 50vh;
  width: 100%;
  position: relative;

  .__curve {
    height: 90%;
    width: 50%;
    position: absolute;
  }

  .__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 1366px;
    width: 100%;
    padding: 20px;
    position: relative;
  }

  .__card_row {
    display: flex;
    width: 100%;

    .___about_card {
      align-items: center;
      background-color: white;
      border-radius: 18px;
      display: flex;
      text-align: left;
      width: 100%;
      margin: 0 0 10%;
      max-height: 208px;
      position: relative;
      padding: 20px;
    }

    .___about_card img {
      aspect-ratio: 1;
      width: 90px;
      max-width: 140px;
      margin: 0 15px 0 0;
    }
  }

  h3 {
    color: colors.$wet-green;
  }

  p {
    margin: 0;
  }
}

@media (min-width: 1200px) {
  .aboutUs {
    display: inline-flex;
    justify-content: center;
    text-align: center;
    padding: 120px 0;
    min-height: 50vh;
    width: 100%;

    .__content {
      max-width: 1366px;
      width: 100%;
      padding: 65px 0;
    }

    .__card_row {
      justify-content: center;

      .___about_card {
        align-items: normal;
        flex-direction: column;
        height: 298px;
        justify-content: center;
        width: 50%;
        margin: 0 25% 8% 0;
        padding: 0 5% 0 10%;
      }

      .___about_card img {
        position: absolute;
        width: auto;
        left: -40px;
        margin: 0;
      }

      .___about_card p {
      }

      .___about_card p {
      }

      &:nth-child(odd) .___about_card {
        margin: 0 0 10% 25%;
      }

      &:nth-child(odd) .___about_card img {
        top: -20px;
      }

      &:nth-child(3n + 1) .___about_card img {
        bottom: -20px;
        top: auto;
      }
    }
  }
}
</style>
