<template>
  <section id="Quienes-somos" class="aboutUsBanner" data-aos="fade-down">
    <div class="__content">
      <h2><b>{{ data.title }}</b></h2>
      <p v-if="data.subtitle">{{data.subtitle}}</p>
      <img src="@/assets/img/aboutUsteam.webp" alt="team" >
<!--      <img src="@/assets/img/banner-quienes-somos-mobile.webp" class="d-block d-md-none" alt="team">-->
    </div>
  </section>
</template>

<script>
import store from "../../store";

export default {
  name: "QuienesSomosBanner",
  data() {
    return {
      data: {
        title: 'Quienes Somos',
        subtitle: ''
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    "$store.state.sections": function() {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
        if (store.state.sections.aboutUsBanner) {
      this.data = {...store.state.sections.aboutUsBanner.components};}
    }
  }
}
</script>

<style scoped lang="scss">
.aboutUsBanner {
  height: 280px;
  width: 100%;
  position: relative;

  .__content {
    background-image: url("../../assets/img/rigth-grey-oval.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom left;
    height: 280px;
    text-align-all: center;
    max-width: 1400px;
    width: 100%;
    padding: 20px;
    position: relative;

    img {
      bottom: 0;
      position: absolute;
      height: 240px;
      max-height: 600px;
      right: 5%;
    }
  }
}

@media (min-width: 992px) {
  .aboutUsBanner {
    align-items: center;
    display: flex;
    height: 580px;
    justify-content: flex-start;
    margin: 5% 0 0 0;

    .__content {
      align-items: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom left;
      display: flex;
      height: 330px;
      text-align-all: center;
      width: 100%;
      padding: 0 0 0 13%;
      img {
        height: auto;
      }
    }
  }
}
</style>
