<template>
  <div class="container __problemas-container my-5" data-aos="fade-down">
    <div class="mb-5 d-md-none d-block text-center">
      <h3>{{ data.preTitle }}</h3>
      <h2>
        {{ data.blackTitle }}
        <span style="color: #14993c"> {{ data.greenTitle }} </span>
      </h2>
    </div>
    <div class="row align-items-center">
      <!--        columna prncipal-->
      <div class="col-12 col-lg-4 order-3 order-md-1 d-flex d-lg-block flex-column align-items-center">
        <div class="mb-5 d-md-block d-none">
          <h3>{{ data.preTitle }}</h3>
          <h2>
            {{ data.blackTitle }}
            <span style="color: #14993c"> {{ data.greenTitle }} </span>
          </h2>
        </div>
        <img
          src="@/assets/img/hombre_pensativo.webp"
          class="img-fluid d-none d-md-block responsive-img"
          alt="Imagen de hombre pensando en desafíos"
          loading="lazy"
        />
        <img
          src="@/assets/img/hombre_pensativo_mobile.webp"
          class="img-fluid d-block d-md-none"
          alt="Imagen de hombre pensando en desafíos version movil"
          loading="lazy"
        />
      </div>
      <!--        columna secundaria (features)-->
      <div class="col-12 col-lg-8 order-1 order-md-2">
        <div class="row">
          <div class="col-12 col-md-6" v-for="(feature, i) in data.features" :key="feature.id">
            <div class="d-flex align-items-center my-4 mx-2">
              <div class="border-card">
                <img
                  :src="require(`../../assets/${feature.img}`)"
                  :alt="`Ícono desafío numero ${i + 1}`"
                  class="card-image "
                  loading="lazy"
                />
              </div>

              <div class="card-content shadow-sm">
                <p>
                  {{ feature.text }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../store';

export default {
  name: "Problemas",
  data() {
    return {
      data: {
        blackTitle: "¿Te identificas con",
        greenTitle: "estos desafíos?",
        preTitle: "Estimado Empresario",
        features: [
          {
            id: 1,
            img: 'img/icono_problemas_1.svg',
            text: 'Tener un modelo de negocio que permita crecer y competir en tiempos de grandes cambios.'
          },
          {
            id: 2,
            img: 'img/icono_problemas_5.svg',
            text: 'Tener objetivos claros para todos.'
          },
          {
            id: 3,
            img: 'img/icono_problemas_2.svg',
            text: 'Tomar acción rápida frente a problemas y oportunidades de mejora.'
          },
          {
            id: 4,
            img: 'img/icono_problemas_6.svg',
            text: 'Tomar acción rápida frente a problemas y oportunidades de mejora.'
          },
          {
            id: 5,
            img: 'img/icono_problemas_3.svg',
            text: 'Tomar acción rápida frente a problemas y oportunidades de mejora.'
          },
          {
            id: 6,
            img: 'img/icono_problemas_7.svg',
            text:' Tomar acción rápida frente a problemas y oportunidades de mejora.'
          },
          {
            id: 7,
            img: 'img/icono_problemas_4.svg',
            text:' Conocer el valor de tu empresa y sentirte seguro para abordar rondas de inversión.'
          },
          {
            id: 8,
            img: 'img/icono_problemas_8.svg',
            text: 'Lograr que las nuevas innovaciones que piensas se ejecuten a través de proyectos que las hagan realidad.'
          },
        ]
      }
    };
  },
  methods: {
    fetchData() {
         if (store.state.sections.challenges) {
      this.data = {...store.state.sections.challenges.components}; }
    }
   
  },
  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    },
  },

  mounted() {
    this.fetchData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->


<style scoped lang="scss">

.__problemas-container {
  .border-card {
  }

  .card-image {
    margin-right: -3rem;
    width: 100px;
  }

  .card-content {
    padding: 1rem 3.2rem;
    display: flex;
    align-items: center;
    width: 100%;
  }

  p {
    font-size: 0.813rem !important;
  }

  h3 {
    font-family: InterMedium !important;
    font-size: 1.25rem !important;
  }
}

@media (min-width: 762px) {
  .__problemas-container {
    .card-content {
      height: 150px;
      padding-right: 15px;

    }

    .responsive-img {
      width: 50%;
    }
  }
}

@media(min-width: 1000px) {
  .__problemas-container {
    .card-content {
      min-height: 100px;
    }

    .responsive-img {
      width: 100%;
    }
  }
}

@media (min-width: 1200px) {
  .__problemas-container {
    .card-content {
      min-height: 120px;

      p {
        font-size: 1rem !important;
      }
    }

    .card-image {
      width: auto;
    }
  }
}

</style>
